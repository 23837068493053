<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('lead.assignedAgentDetail')"
    class="px-5 py-3"
  >
    <div v-if="assignedAgent">
      <v-col cols="12" class="text-right add--button">
        <v-btn color="success" class="bottom--button mr-0" @click="openAgentForm">
          {{ $t('agent.btn.edit') }}
        </v-btn>
      </v-col>
      <assigned-agent-detail-content
        :assignedAgent="{ data: assignedAgent }"
      ></assigned-agent-detail-content>
    </div>
    <div class="center--button" v-else>
      <v-btn color="success" class="mr-0" @click="openAgentForm">
        {{ $t('agent.btn.assign') }}
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const AssignedAgentDetailContent = () =>
  import('@/components/transaction-management/lead/partials/assigned-agent-detail-content');

export default {
  mixins: [HelperMixin],
  props: ['assignedAgent'],
  components: {
    AssignedAgentDetailContent,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    openAgentForm() {
      this.$modal.show('modal-agent', {
        agent: this.assignedAgent,
        commitString: 'lead/form/SET_ASSIGNED_AGENT',
        dispatchString: 'lead/form/assignAgent',
        dispatchAfterString: 'lead/form/fetchBaseData',
      });
    },
  },
};
</script>
